import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.light.css';
import React from 'react';
import { useAuth0 } from "./react-auth0-spa";
import { HashRouter, Route, Switch } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './scss/style.scss';
import { locale } from 'devextreme/localization';

const loading = () => <div className="animated fadeIn pt-3 text-center"><div className="sk-spinner sk-spinner-pulse"></div></div>;

// Containers
const TheLayout = React.lazy(() => import('./containers/TheLayout'));

const App = () => {
  const { isAuthenticated, loginWithRedirect, user, auth0Client } = useAuth0();

  locale('es');

  if (isAuthenticated === false) {
    auth0Client.logout({
      returnTo: window.location.origin
    });
    return loginWithRedirect({});
  }

  if (!user) {
    return null;
  } 
  
  return (
      <HashRouter>
          <React.Suspense fallback={loading()}>
            <Switch>
              <Route path="/" name="Home" render={props => <TheLayout {...props} />} />
            </Switch>
          </React.Suspense>
          <ToastContainer />
      </HashRouter>
  );
}

export default App;
